<template>

      <v-container>
        <v-row>
           <v-col cols="12" md="3">
             <v-card outlined>
               <div class="user-avatar p-3 d-flex align-center">
                 <v-row
                 align="center"
                 class="fill-height" >
                  <v-col class="pa-0" align="center" cols="12">
                    <v-avatar class="profile rounded-circle" size="110" tile >
                      <img
                        src="@/assets/etousoft-logo.png"
                        alt="John"
                      >
                    </v-avatar>
                  </v-col>
                  
                 </v-row>
               </div>
               <v-card-text v-if="info">
                 <p class="headline black--text mb-0 font-weight-bold">
                   {{ info.first_name }} {{ info.last_name }}</p>
                 <p class="text-secondary mt-0 mb-0">{{ info.default_user_company.role | role }}</p>
                 <v-divider> </v-divider>
                 <v-btn text block color="#0A009B" :loading="loadings.generate" @click="generate" v-if="display.generate"> 
                    Generate Referral link
                  </v-btn>
                  <v-menu offset-y v-else transition="slide-y-transition" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="#0A009B"
                          text
                          v-bind="attrs"
                          v-on="on"
                          block
                        >
                      
                          Invitation Links
                        </v-btn>
                      </template>
                        <v-list class="p-0" dense elevation="0">
                          <v-list-item class="border-bottom" v-for="product in products" :key="product.id" link @click="copy(product.invitation, product.name)">
                            <v-list-item-icon>
                              <v-btn icon  >
                                <v-icon color="#0A009B" >mdi-content-copy</v-icon> 
                              </v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="black--text">{{ product.name }}</v-list-item-title>
                              <v-list-item-subtitle> {{ product.invitation }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="border-bottom" v-if="display.more">
                            <v-list-item-content>
                              <v-list-item-title class="text-center"> <v-btn text color="#0A009B" block small @click="loadMore"> Load More </v-btn> </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </v-list>
                    </v-menu>
                <p class="black--text mb-1 mt-2">Invite your friends through this link to earn income for every sign up. </p>
                 <p class="text-secondary mt-0 mb-0">Referral Code <v-btn icon class="ml-2" @click="copy(info.referral_code)"> <v-icon color="#0A009B" >mdi-content-copy</v-icon> </v-btn> </p>
                 <p class="black--text font-weight-bold h6  mt-0 ">   {{ info.referral_code ? info.referral_code : 'N/A' }} </p>
               </v-card-text>
               <v-card-text v-else>
                 <p class="headline text-dark mb-0 font-weight-bold">
                   Loading... </p>
                 <p class="text-secondary mt-0 mb-0"> Loading...</p>
                 <v-divider> </v-divider>
                 <p class="text-secondary mt-0 mb-0">Invitation Link</p>
                 <p class="text-primary mt-0 mb-0"> Loading... </p>
               </v-card-text>
               <v-card-actions class="border-top">
                  <v-btn block text color="#0A009B"  @click="editPassword" :loading="loadings.profile">
                  <v-icon class="border rounded-circle p-1 mr-2">mdi-key</v-icon>
                  <span> Change Password </span>
                 </v-btn>
               </v-card-actions>
             </v-card>
           </v-col>
           <v-col cols="12" md="9">
             <v-card outlined>
               <v-tabs color="#0A009B">
                 <v-tab>Profile</v-tab>
                  <v-tab-item>
                    <v-container>
                      <v-row v-if="info">
                       <v-col cols="12" md="4">
                         <p class="text-caption mb-0 text-secondary text-uppercase ">First Name</p>
                         <p class=" mt-0 mb-0">{{ info.first_name ? info.first_name : 'N/A' }}</p>
                        
                       </v-col>
                       <v-col cols="12" md="4">
                         <p class="text-caption mb-0 text-secondary text-uppercase ">Middle Name</p>
                         <p class=" mt-0 mb-0">{{ info.middle_name ? info.middle_name : 'N/A' }}</p>
                        
                       </v-col>
                       <v-col cols="12" md="4">
                         <p class="text-caption mb-0 text-secondary text-uppercase ">Last Name</p>
                         <p class=" mt-0 mb-0">{{ info.last_name ? info.last_name : 'N/A' }}</p>
                        
                       </v-col>
                      <v-col cols="12" md="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Country</p>
                        <p class=" mt-0 mb-0"> {{  info.country ? info.country : 'N/A' }}</p>
                       </v-col>
                       <v-col cols="12" md="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Address</p>
                        <p class=" mt-0 mb-0"> {{  info.address ? info.address : 'N/A' }}</p>
                       </v-col>

                       <v-col cols="12" md="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Timezone</p>
                        <p class=" mt-0 mb-0"> {{  info.timezone ? info.timezone : 'N/A' }}</p>
                       </v-col>
                       <v-col cols="12" md="4" lg="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Email</p>
                        <p class=" mt-0 mb-0">{{ info.email ? info.email : 'N/A' }}</p>
                      
                       </v-col>
                       <v-col cols="12" md="4" lg="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Phone</p>
                        <p class=" mt-0 mb-0" >{{info.phone_number ? info.phone_number : '' | phone  }}</p>
                       </v-col>
                        <v-col cols="12" md="4" lg="4">
                        <p class="text-caption mb-0 text-secondary text-uppercase ">Referred By</p>
                        <p class=" mt-0 mb-0" >{{ referral && referral.referror ? referral.referror.first_name + ' ' + referral.referror.last_name : 'N/A' }}</p>
                       </v-col>
                      </v-row>
                    </v-container>
                 </v-tab-item>
               </v-tabs>
               <v-card-actions class="border-top">
                 <div class="w-100 text-right d-block ">
                  <v-btn text color="#0A009B" @click="editProfile" :loading="loadings.profile">
                    Edit
                  </v-btn>
                 </div>
               </v-card-actions>
             </v-card>
           </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.show" top right :color="snackbar.color" timeout="2000">
          <v-icon class="mr-2">{{ snackbar.icon }}</v-icon>
          <span> {{ snackbar.text }} </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <EditProfile v-bind:user="info" @refresh="refreshMe" v-bind:dialog="dialogs.profile" v-model="dialogs.profile"> </EditProfile>
        <ChangePassword v-bind:user="info" @refresh="refreshMe" v-bind:dialog="dialogs.change_password" v-model="dialogs.change_password"></ChangePassword>
      </v-container>

</template>
<script>
import EditProfile from "@/components/Profile/EditProfile";
import ChangePassword from "@/components/Profile/ChangePassword";

export default {
  components: {
    EditProfile,
    ChangePassword,
  },
  props: {
    myInfo : Object,
  },
  data() {
    return {
      yourinfoLoading: false,
      toggle_exclusive: undefined,
      snackbar: {
        show: false,
        color: "success",
        text: "Profile updated!",
        icon: "mdi-check",
      },
      info: null,
      referral : null,
      company: [],
      company_list: [],
      products: null,
      link: null,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      loadings : {
        profile : true,
        generate : false
      },
      status: {
        admin: false,
        product: false
      },
      display: {  
        generate : true,
        more : false
      },
      dialogs: {
        change_password : false,
        profile: false,
        generate : false
      },
      ChangeProfile: null,
      url: null,
    };
  },
  created() {
    if(this.myInfo)
    {
      this.info = this.myInfo
      this.yourinfoLoading = false
      this.setInfo()
    }

  },
  watch: {
    myInfo: function(val)
    {
      this.info = val
      this.setInfo()
    }
  },
  methods: {
    loadMore()
    {

    },
    generate()
    {
      this.loadings.generate = true

     this.getProduct()
    },
    Preview_image() {
      this.info.image = URL.createObjectURL(this.ChangeProfile);
    },
    setInfo()
    {
      if(this.info.default_user_company.role == 'SA')
      {
        this.status.admin = true
      }
      this.getInviter()
      this.info.timezone = this.info.default_user_company.timezone.timezone.split(',')[0]

      this.link =
          'http://stagingacc.etousoft.com/sa/' + 
          this.info.referral_code
          
          
      this.loadings.profile = false
      
    },
    getInviter()
    {
      this.axios.get('inviter?user_id='+this.info.id)
      .then( ({data}) => {
        this.referral = data.inviter[0]
      })

    },
    getInfo() {
      this.yourinfoLoading = true;
      this.axios
        .get("/users/me")
        .then(({ data }) => {
          this.info = data.user;
          this.setInfo()
        })
        .catch()
        .finally(() => (this.yourinfoLoading = false));
    },

    refreshMe(msg) {
      if (msg == "u") {
        this.snackbar.icon = "mdi-check";
        this.snackbar.text = "Profile updated!";
        this.snackbar.color = "success";
      }
      if (msg == "p") {
        this.snackbar.icon = "mdi-lock";
        this.snackbar.text = "Password Updated!";
        this.snackbar.color = "success";
      }
      this.$emit('refresh', true)
      this.snackbar.show = true;

    },

    copy(data, message) {
      let m = 'Referral Code'
      if(message)
      {
        m = message
      }
      this.snackbar.icon = "mdi-check-underline";
      this.snackbar.text = m + " Copied!";
      this.snackbar.color = "success";

      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar.show = true;
    },
    editPassword()
    {
      this.dialogs.change_password = true
    },
    editProfile()
    {
      this.dialogs.profile = true
    },
    getProduct()
    {
       if(this.info)
          {
            
              this.axios.get("/products?subscription=true&user_company_id="+this.info.default_user_company.id).then(({ data }) => {
                  if(data.products.length > 0)
                  { for(let i in data.products)
                      {
                        data.products[i].invitation = data.products[i].url + '?ref='+this.info.referral_code
                      }
                  }
                this.products = data.products
                this.loading = false
            })
            .finally( () => {
              this.loadings.generate = false
              this.display.generate = false
            })
          }
    }
  },
};
</script>


<style scoped>
button:focus,
button:active{
  outline: none;
}
#router {
  text-decoration: none;
}
#btn {
  text-transform: none;
}
#fileInput {
  z-index: auto;
}
.user-avatar{
  height: 130px;
  position: relative;
  overflow: hidden;
}
.bg-main{
  background-color: #01577a;
}
</style>