<template>
  <div>
    <v-snackbar v-model="snackbar.show" outlined top  right :color="snackbar.color" timeout="5000">
      <div class='d-flex align-items-center'>
        <div class='pr-2'> 
          <v-icon v-if='snackbar.color == "success"' color='green'>mdi-check</v-icon>
          <v-icon v-if='snackbar.color == "warning"' color='orange'>mdi-alert</v-icon>
        </div>
        <div class='pl-2'>
          <p class='mb-0' v-for='(message, i) in snackbar.message' :key='i'>  {{  message }} </p>
        </div>
      
      </div>
    
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-toolbar color="#00439B" flat>
        <v-toolbar-title class="white--text">Change Password</v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="updatePassword" ref="form" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Email"
                    type="email"
                    v-model="email"
                    required
                    readonly
                    :rules="rules.email"
                    autocomplete="new-password"
                    placeholder="Please provide your email address"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Your password"
                    type="password"
                    v-model="form.password"
                    required
                    :rules="rules.password"
                    :error-messages="passwordIncorrect"
                    autocomplete="current-password"
                    :disabled="overlay"
                  >
                  </v-text-field>
                  <v-text-field
                    label="New password"
                    type="password"
                    v-model="form.new_password"
                    required
                    :rules="rules.new"
                    autocomplete="new-password"
                    :disabled="overlay"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Confirm new password"
                    type="password"
                    v-model="form.confirm_password"
                    required
                    :rules="rules.confirm"
                    autocomplete="new-password"
                    :disabled="overlay"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn  text class="text-secondary" @click="closePassword" :disabled="overlay">
              Close
              </v-btn>
              <v-btn color="#0A009B" text type="submit" :loading="overlay"> Update </v-btn>
            </div> 
            
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  props: ["user" , 'dialog'],
  data() {
    return {
      info: [],
      overlay: false,
      absolute: true,
      email: null,
      form: {
        password: null,
        new_password: null,
        confirm_password: null,
      },
      snackbar:
      {
        show: false,
        color: 'success',
        message: [],
      },
      pass_err: false,
      rules: {
        email: [
          (value) => !!value || "Please provide email address",
          (value) =>
            /.+@.+\..+/.test(value) || "Please provide valid emial address",
        ],
        password: [(value) => !!value || "Please provide your password"],
        new: [
          (value) => !!value || "Please provide new passowrd",
          (value) => /[0-9]/.test(value) || 'Please provide a number [0-9]',
        ],
        confirm: [
          (value) => !!value || "Please confirm your new passowrd",
          (value) => value === this.form.new_password || "Password not match",
          (value) => /[0-9]/.test(value) || 'Please provide a number [0-9]',
        ],
      },
    };
  },
  computed: {
    passwordIncorrect() {
      const error = [];
      if (this.pass_err) {
        error.push("Incorrect password please try again");
      }
      return error;
    },
    show: {
      get() {
        if(this.user)
        {
          this.populate()
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    user(val)
    {
      if(val)
      {
        this.populate()
      }
    }
  },
  methods: {
    updatePassword() {
      if (this.$refs.form.validate()) {
        this.loading();
        this.checkPassword();
      }
    },
    closePassword() {
      this.show = false;
      this.reset();
    },
    reset() {
      this.pass_err = false;
      
      this.$refs.form.resetValidation();
      for(let i in this.form)
      {
        this.form[i] = null
      }
    },
    checkPassword() {
      let data = {
        email: this.email,
        password: this.form.password,
      };
      this.axios
        .post("/authenticate", data)
        .then(({ data }) => {
          if (data) {
            this.post();
          }
        })
        .catch((err) => {
          this.pass_err = true
          this.requestCatch(err)
        });
    },
    requestCatch(err)
    {
      this.snackbar.message = []
      var er = err.response.data
      this.stopLoading();
      this.snackbar.color = 'warning'
      if (er.error)
      {
        this.snackbar.message.push(er.error)
      } else {
        this.snackbar.message.push('Something went wrong please try again later')
      }
      this.snackbar.show = true
    },
    loading() {
      this.overlay = true;
    },
    stopLoading() {
      this.overlay = false;
    },
    populate()
    {
      this.email = this.user.email
    },
    post() {
      this.axios
        .post("/set_new_password", {
          email: this.email,
          password: this.form.new_password,
          password_confirmation : this.form.confirm_password,
        })
        .then(() => {
          
          this.overlay = false;
          this.snackbar.color = 'success'
          this.snackbar.message = []
            this.snackbar.message.push('Password Updated')
            this.snackbar.show = true
            this.show  = false;
            this.$user.Logout()
            this.axios.post("/logout").then( window.location.href = this.$config.login )
          
        }).catch((err) => {
          this.requestCatch(err)
        });
    },
  },
};
</script>
